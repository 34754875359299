import {
  Avatar,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./Orders.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
import { API_URL } from "../Config";


// Define the structure of the order data
interface User {
  _id: string;
  name: string;
  email: string;
  phone: number;
}

interface LineItem {
  product: any;
  quantity: number;
  _id: string;
}

interface Order {
  _id: string;
  orderId: string;
  user: User;
  lineItems: LineItem[];
  createdAt: string;
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  deliveryDate: string | null;
  paymentStatus: string;
  paymentAmount: number;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string;
  cancellationDate: string;
  refundStatus: string;
  isDeleted: boolean;
  __v: number;
}

const OrderCanceled = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState<Order | null>(null);
  const [canceldata, setcanceldata] = useState({
    cancellationReason: "",
    isCancelled: true,
    cancellationDate: new Date(),
  });

  const formatDate = (dateString: any) => {
    return moment(dateString).format("ddd, Do MMM");
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content:any) => {
    messageApi.open({
      type: 'success',
      content: content,
    });
  };

  const errormessage = (content:any) => {
    messageApi.open({
      type: 'error',
      content: content,
    });
  };


  const getOrderbyId = async () => {
    try {
      let fetchOrder = await axios.get(`https://api.meatmarket.live/api/order/${id}`);
      setData(fetchOrder.data.order);
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    if (id) {
      getOrderbyId();
    }
  }, [id]);

  return (
    <>
      <section className="my-3 pt-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"} <Link to="/my-order">My Orders</Link> {">"} <span>Order Canceled</span>
          </p>{" "}
          <div
            className="row mx-0"
            style={{
              backgroundColor: "#fff",
              border: "1px solid red",
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-2">
                <p className="mb-1" style={{ fontWeight: 700 }}>
                  Delivery Address
                </p>
                <p className="mb-1" style={{ fontWeight: 600 }}>
                {data?.user?.name || "Name not available"}
                </p>
                <p className="mb-1" style={{ fontWeight: 400 }}>
                {data?.address || "Address not available"}
                </p>
                <p className="mb-1">
                  <span className="pe-2" style={{ fontWeight: 700 }}>
                    Phone number
                  </span>
                  <span style={{ fontWeight: 400 }}>  {data?.user?.phone || "Phone number not available"}</span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="row mt-4 mx-0 sm-hide-Odetails"
            style={{ backgroundColor: "#F4F4F4" }}
          >
            <div
              className="d-flex justify-content-between align-items-center px-0"
              style={{ backgroundColor: "##F4F4F4", overflow: "hidden" }}
            >
              <div className="col-lg-4 col-xl-4 col-sm-12 col-md-4 px-0">
                <div className="d-flex justify-content-start align-items-center">
                  <div>
                    <Avatar
                      className="avatarOrder"
                      alt="Remy Sharp"
                      src={
                        data?.lineItems[0]?.product?.image
                          ? `${API_URL}/images/${data.lineItems[0].product.image}`
                          : "/path/to/default/image.jpg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "2rem" }}>
                    <p className="mb-0 order-itemname">   {data?.lineItems && data.lineItems.length > 0
                        ? data.lineItems
                            .map((val) =>
                              val.product?.name ? val.product.name : "Unknown product"
                            )
                            .join(", ")
                        : "No products available"}</p>
                    <p className="mb-0" style={{ color: "#454545" }}>
                    {data?.lineItems && data.lineItems.length > 0
                        ? data.lineItems.map((val) => val.quantity).join(", ")
                        : "No quantity available"}{" "}gm
                    </p>
                    <p className="mb-0" style={{ fontWeight: 600 }}>
                      ₹ {data?.paymentAmount.toFixed() || "Price not available"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5  col-xl-5 col-sm-12 col-md-5 d-flex justify-content-center align-items-center">
                <div style={{ width: "100%" }}>
                  <div className="Wm9vLv" style={{ width: "100%" }}>
                    <div className="q4l-J2" style={{ width: "100%" }}>
                      <div className="YrA9Sn">
                        <div className="SfJXc8O">
                          <span>Order Confirmed</span>
                        </div>
                        <div className="GS3O1E T7AO1y order-cancel sDEgvV"></div>
                        <div className="_9MhawT-cancel">
                          <div className="_9XeNR9 order-cancel"></div>
                        </div>
                        <div className="SfJXc8">
                          <span>{formatDate(data?.createdAt)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="q4l-J2">
                      <div className="YrA9Sn">
                        <div className="SfJXc8O-incomplete">
                          <span style={{ color: "#0D354F" }}>Canceled</span>
                        </div>
                        <div className="GS3O1E T7AO1y order-cancel sDEgvV "></div>

                        <div className="SfJXc8">
                          <span>{formatDate(data?.cancellationDate)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="me-3">
                <p
                  className="my-2"
                  style={{
                    color: "#001524",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Order cancelled successfully.
                </p>
                <div className="d-flex justify-content-between align-items-center cancel-times">
                {data?.cancellationReason}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OrderCanceled;
