import React from 'react'
import { Container } from 'react-bootstrap'

const Termscon = () => {
    return (
        <>
          <section className='py-3'>
            <Container>
                <h2>Terms & condition</h2>
                <p>Welcome to Meat Market! These terms and conditions outline the rules and regulations for the use of our website and services. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>
                <p>When creating an account, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.</p>
                <p>The Meat Market website is intended for lawful use only. You must not use the website in any way that could damage, disable, or impair its functionality or interfere with other users’ access and enjoyment. Unauthorized attempts to access any part of the website, or any systems connected to it, are strictly prohibited.</p>
                <p>All content on the Meat Market website, including text, images, logos, and software, is the property of Meat Market or its content suppliers and is protected by intellectual property laws. You may not copy, reproduce, distribute, or create derivative works from any content on our website without prior written permission from Meat Market.</p>
                <p>We strive to provide accurate product descriptions and pricing information on our website, but errors may occur. We reserve the right to correct any inaccuracies and update product information as necessary. Prices are subject to change without notice, and all prices are listed in Indian Rupees (INR) and include applicable taxes unless otherwise stated.</p>
                <p>If you submit content to our website, such as reviews or comments, you grant Meat Market a non-exclusive, royalty-free license to use, reproduce, and distribute your content. You warrant that the content you submit is accurate, lawful, and does not infringe on the rights of any third party.</p>
                <p>These Terms of Use are governed by and construed in accordance with the laws of India. Any disputes arising from these terms or your use of the website will be subject to the exclusive jurisdiction of the courts in Chennai.</p>
                <p>If you have any questions or concerns regarding these Terms of Use, please contact us.</p>
            </Container>
            </section>  
        </>
    )
}

export default Termscon
