import React, { useEffect } from 'react'
import Header from '../Home/Header';
import Footer from '../Footer/Footer';
import Termscon from '../Home/Termscon';

const TermsCondition = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <>
            <Header/>
            <Termscon/>
            <Footer/>
        </>
    )
}

export default TermsCondition
