import React, { useEffect, useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { fetchBannerData } from "../Providers/Api_Calls";
import { API_URL } from "../Config";
import { useNavigate } from "react-router-dom";

const CarouselContainer = styled.div`
  .slick-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }

  .slick-center img {
    transform: scale(1); /* Normal size for the centered slide */
  }

  .slick-slide:not(.slick-center) img {
    transform: scale(1.1); /* Scale up non-centered slides */
    border: 5px solid #fff;
    border-radius: 10px;
    overflow: hidden;
  }

  .slick-slide {
    opacity: 1;
  }

  .slick-center {
    opacity: 1;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    font-size: 24px;
    color: #000;
  }

  .slick-prev {
    left: 10px;
    display: none;
  }

  .slick-next {
    right: 10px;
    display: none;
  }

  .slick-track {
    left: 2px;
  }
`;

type BannerType = {
  _id: string;
  heading: string;
  content: string;
  background: string;
  image: string;
};

const Banner: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [bannerData, setBannerData] = useState<BannerType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isstaticBanner, setIsStaticBanner] = useState<BannerType[]>([]);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 2,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
  };

  const fallbackData = [
    {
      heading: "Leg of Mutton",
      content:
        "High in Protein and Iron: Supports muscle health and energy levels.",
      image: "/assets/banner1.png",
      background: "/assets/bgmutton.png",
    },
    {
      heading: "Chicken Breast",
      content: "High in Protein: Excellent for muscle building and repair.",
      image: "/assets/banner2.png",
      background: "/assets/banner-bg.png",
    },
    {
      heading: "Chicken Wings",
      content: "Popular for Snacks: Great for appetizers and parties.",
      image: "/assets/banner3.png",
      background: "/assets/banner-bg.png",
    },
    {
      heading: "Chicken Thighs",
      content: "Rich Flavor: More flavorful and moist compared to breast.",
      image: "/assets/banner4.png",
      background: "/assets/banner-bg.png",
    },
    {
      heading: "Mutton Ribs",
      content: "Delicious and Tender: Excellent for grilling or barbecuing.",
      image: "/assets/banner5.png",
      background: "/assets/bgmutton.png",
    },
  ];

  const getBannerDetails = async () => {
    try {
      const getBanner = await fetchBannerData(ApiEndPoints("getBanner"));
      const currentDate = new Date();
      let carsoulBanner = getBanner.heroPages.filter(
        (item: any) => item.isTemporary === false
      );
      const staticBanner = getBanner.heroPages.filter(
        (item: any) =>
          item.isTemporary === true &&
          new Date(item.startDate) <= currentDate &&
          new Date(item.endDate) >= currentDate
      );
      setBannerData(carsoulBanner || []);
      setIsStaticBanner(staticBanner || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching banner data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: settings.autoplaySpeed / 2, // Set duration as a fraction of autoplay speed
      once: true, // Animation happens only once
    });
    getBannerDetails();
  }, []);

  const currentBanner =
    (bannerData.length > 2
      ? bannerData[currentSlide]
      : fallbackData[currentSlide % fallbackData.length]) || {};
  const prevBannerIndex =
    (currentSlide - 1 + bannerData.length) % bannerData.length; // Wrap around

  // Determine if the current banner is from API data or fallback
  const isApiData = bannerData.length > 2;

  const navigate = useNavigate();
  const handleBannerNav = (index: any) => {
    let chick = index.heading.includes("Chicken");
    if (chick) {
      navigate("/chicken");
    } else {
      navigate("/mutton");
    }
  };

  return (
    <>
      {isLoading ? (
        <p>Loading...</p> 
      ) : (
        <>
          {!isstaticBanner.length ? (
            <section
              className="banner"
              style={{
                backgroundImage: `url(${
                  isApiData
                    ? `${API_URL}/images/${currentBanner.background}`
                    : currentBanner.background
                })`,
                transition: "background-image 1s ease-in-out",
              }}
            >
              <Container style={{ height: "100%" }}>
                <div className="row" style={{ height: "100%" }}>
                  <div
                    className="col-lg-7 col-md-6 col-sm-12 d-flex justify-content-start align-items-center"
                    style={{ height: "100%", position: "relative" }}
                  >
                    <div className="banner-con">
                      <h1 data-aos="fade-up">
                        {bannerData[prevBannerIndex].heading || ""}
                      </h1>
                      <p data-aos="fade-up">
                        {bannerData[prevBannerIndex].content || ""}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 sm-banner banner-carsoul">
                    <CarouselContainer>
                      <Slider {...settings}>
                        {(bannerData.length > 2
                          ? bannerData
                          : fallbackData
                        ).map((item, index) => (
                          <div
                            key={index}
                            className="p-3"
                            style={{ borderRadius: "10px" }}
                          >
                            <img
                              className="img-fluid"
                              src={
                                item.image
                                  ? isApiData
                                    ? `${API_URL}/images/${item.image}`
                                    : item.image
                                  : ""
                              }
                              alt={item.heading}
                              style={{ borderRadius: "10px" }}
                              onClick={() => handleBannerNav(item)}
                            />
                          </div>
                        ))}
                      </Slider>
                    </CarouselContainer>
                  </div>
                </div>
              </Container>
            </section>
          ) : (
            <section>
              <Carousel>
                {isstaticBanner.map((item, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={`${API_URL}/images/${item.background}`}
                      className="img-fluid"
                      style={{width:"100%"}}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </section>
          )}
        </>
      )}
      <section className="py-2" style={{ borderBottom: "2px solid #0D354F" }}>
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="field-expri p-2">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    width={"50px"}
                    src="/assets/halal.png"
                  />
                </div>
                <div>
                  <p className="b-Para mb-0">100% Halal Meat</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="field-expri p-2">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    width={"70px"}
                    src="/assets/b1.png"
                  />
                </div>
                <div>
                  <p className="b-Para mb-0">20+ Years Of Experience</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="field-expri p-2">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    width={"70px"}
                    src="/assets/b2.png"
                  />
                </div>
                <div>
                  <p className="b-Para mb-0">Proudly 100% Organic</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="field-expri p-2">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    width={"60px"}
                    src="/assets/b3.png"
                  />
                </div>
                <div>
                  <p className="b-Para mb-0">100% Fresh Meat</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Banner;
