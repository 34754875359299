import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlayCircle } from "react-icons/fa";

const Recipe = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: false,
  };

  return (
    <>
      <section className="py-4" style={{ overflow: "hidden" }}>
        <Container>
          <div className="slider-container">
            <Slider {...settings}>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img "
                      src="/assets/chickenbriyani.png"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                      {/* <FaPlayCircle className="playicon"/> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Hyderabadi Biryani</h2>
                    <p>
                      Hyderabadi Biryani is a flavorful and aromatic dish
                      originating from Hyderabad, India. It is known for its
                      unique taste, rich spices, and layered rice and meat.
                      Here’s a detailed recipe for making traditional Hyderabadi
                      Biryani.
                    </p>
                    <h3>Ingredients:</h3>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div>
                          <h6>For the Rice</h6>
                          <ul>
                            <li>2 cups Basmati rice</li>
                            <li>4 cups water</li>
                            <li>2-3 green cardamom pods</li>
                            <li>4 cloves</li>
                            <li>1-inch cinnamon stick</li>
                            <li>1 bay leaf</li>
                            <li>Salt to taste</li>
                          </ul>
                          <h6>For the Rice</h6>
                          <ul>
                            <li>1/4 cup ghee (clarified butter) or oil</li>
                            <li>1 large onion, thinly sliced</li>
                            <li>
                              A pinch of saffron soaked in 1/4 cup warm milk
                            </li>
                            <li>2 tbsp rose water or kewra water (optional)</li>
                            <li>Fresh coriander and mint leaves for garnish</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div>
                          <h6>For the Marinated Meat</h6>
                          <ul>
                            <li>1 kg chicken/mutton, cut into pieces</li>
                            <li>1 cup yogurt</li>
                            <li>2 tbsp ginger-garlic paste</li>
                            <li>1 tsp turmeric powder</li>
                            <li>1 tbsp red chili powder</li>
                            <li>1 tbsp garam masala powder</li>
                            <li>2-3 green chilies, slit</li>
                            <li>1/2 cup fried onions</li>
                            <li>1/4 cup fresh coriander leaves, chopped</li>
                            <li>1/4 cup fresh mint leaves, chopped</li>
                            <li>2 tbsp lemon juice</li>
                            <li>Salt to taste</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img"
                      src="/assets/muttonbriyani.png"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-sheep playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Djiz-biz</h2>
                    <p>
                      Djiz-biz is a traditional Azerbaijani dish made from lamb
                      offal, including liver, heart, lungs, spleen, kidneys, and
                      fat. It's a rich and hearty meal that's packed with
                      flavor. Here's a detailed recipe to prepare Djiz-biz at
                      home.
                    </p>
                    <h3>Ingredients:</h3>
                    <ul>
                      <li>1 lamb liver</li>
                      <li>1 lamb heart</li>
                      <li>1 lamb lung</li>
                      <li>1 lamb spleen</li>
                      <li>2 lamb kidneys</li>
                      <li>
                        200 grams lamb fat (tail fat or any other fatty part)
                      </li>
                      <li>2 large onions, finely chopped</li>
                      <li>2-3 cloves of garlic, minced</li>
                      <li>2-3 medium tomatoes, chopped (optional)</li>
                      <li>1-2 green bell peppers, chopped (optional)</li>
                      <li>1 teaspoon ground coriander</li>
                      <li>1 teaspoon ground cumin</li>
                      <li>1 teaspoon paprika</li>
                      <li>Salt and black pepper to taste</li>
                      <li>Fresh cilantro or parsley, chopped for garnish</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img"
                      src="/assets/image.png"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-sheep playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Mutton Gee Roast</h2>
                    <p>
                      Mutton Ghee Roast is a rich and flavorful dish that
                      originates from the coastal regions of Karnataka, India,
                      particularly from the city of Mangalore. This dish is
                      known for its deep, spicy flavors and the rich aroma of
                      ghee (clarified butter), which is a key ingredient.
                    </p>
                    <h3>Ingredients:</h3>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>Add 2 Tbsp Clarified Butter/Ghee </li>
                          <li>500gms Mutton</li>
                          <li>Roast this for a few minutes. Then add,</li>
                          <li>1 Tbsp Ginger Garlic Paste</li>
                          <li>1/4 Tsp Turmeric Powder</li>
                          <li>1 Tsp Salt</li>
                          <li>1½ Cup Water</li>
                          <li>
                            Close the lid and pressure cook the mutton till its
                            cooked.
                          </li>
                          <li>
                            Meanwhile, on a pan, Roast 5 Dried Kashmiri Red
                            Chillies and remove.
                          </li>
                          <li>Then on the same pan, Add</li>
                          <li>2 Tbsp Coriander Seeds</li>
                          <li>1/2 Tbsp Cumin Seeds</li>
                          <li>1/2 Tsp PepperCorns</li>
                          <li>1 Tsp Fennel Seeds</li>
                          <li>1 Pinch Fenugreek Seeds</li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>1 Star Anise</li>
                          <li>3 Cloves</li>
                          <li>1 Piece Cinnamon</li>
                          <li>4-5 Dried Red Chillies</li>
                          <li>5-6 Garlic Cloves</li>
                          <li>
                            Roast and add to a grinder along with 1 Small Piece
                            of Tamarind
                          </li>
                          <li>1/4 Cup Curd</li>
                          <li>Water</li>
                          <li>Blend into a paste.</li>
                          <li>
                            Now in a vessel,Add 2 Tbsp Clarified Butter and the
                            red paste we just made.
                          </li>
                          <li>Along with, 2-3 Tbsp Water.</li>
                          <li>
                            Cook this till oil separates then add the cooked
                            mutton pieces along with 1/2 Tsp Salt
                          </li>
                          <li>10-12 Curry Leaves</li>
                          <li>
                            Mix well and cover and cook for 5-10 minutes.
                          </li>
                          <li>
                            Mutton Ghee Roast is ready. Serve with Dosa or
                            Paratha
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img"
                      src="/assets/soup.png"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-sheep playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Goat Spleen Soup</h2>
                    <p>
                    Goat Spleen Soup is a traditional dish found in various cuisines, particularly in regions where goat meat is a common staple. Known for its rich, hearty flavor, this soup is often valued not only for its taste but also for its nutritional benefits.
                    </p>
                   
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                      <h3>Ingredients:</h3>
                        <ul>
                          <li>1 goat spleen, cleaned and chopped into pieces.</li>
                          <li>1 large onion, finely chopped.</li>
                          <li>2 tomatoes, chopped.</li>
                          <li>4 cloves garlic, minced.</li>
                          <li>1-inch piece ginger, minced.</li>
                          <li>2 green chilies, slit.</li>
                          <li>1 tsp cumin seeds.</li>
                          <li>
                          1 tsp coriander powder
                          </li>
                          <li>
                          1/2 tsp turmeric powder
                          </li>
                          <li>1/2 tsp red chili powder (adjust to taste)</li>
                          <li>1 tsp garam masala</li>
                          <li>1 bay leaf</li>
                          <li>4-5 black peppercorns</li>
                          <li>1 cinnamon stick</li>
                          <li>2-3 cloves</li>
                          <li>Salt to taste</li>
                          <li>2 tbsp cooking oil or ghee</li>
                          <li>Fresh coriander leaves for garnish</li>
                          <li>4 cups water or broth</li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                      <h3 >Instructions:</h3>
                      <p style={{fontWeight:600}}>Preparation:</p>
                        <ul>
                          <li>Clean the goat spleen thoroughly and chop it into bite-sized pieces.</li>
                          <li>Prepare the onions, tomatoes, garlic, ginger, and green chilies.</li>
                          
                        </ul>
                        <p style={{fontWeight:600}}>Cooking the Soup:</p>
                        <ul>
                          <li>Heat oil or ghee in a large pot over medium heat. Add the cumin seeds, bay leaf, cinnamon stick, black peppercorns, and cloves. Sauté until fragrant.</li>
                          <li>Add the chopped onions and sauté until they turn golden brown.</li>
                          <li>Add the minced garlic, ginger, and green chilies. Sauté for another minute.</li>
                          <li>Add the chopped tomatoes and cook until they soften and the oil starts to separate.</li>
                          <li>Add the turmeric powder, coriander powder, red chili powder, and salt. Cook for 2-3 minutes to allow the spices to blend.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Recipe;
