import React, { useEffect } from "react";

const Badrequest = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <img
        className="img-fluid"
        style={{ height: "100vh", width: "100%" }}
        src="/assets/404bg.png"
      />
    </div>
  );
};

export default Badrequest;
