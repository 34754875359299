import { Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const Resetpass = () => {
  const { id } = useParams();
  const [resetData, setResetData] = useState({
    password: "",
    conformpass: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const navigate=useNavigate()
  const handleReset = async () => {
    if (resetData.password !== resetData.conformpass) {
      errormessage("Password and conform password must be same");
    }
    else if(resetData.password===""||resetData.conformpass===""){
      errormessage("Please fill both password and conform password");
    } else {
      try {
        const res = await axios.patch(
          `https://api.meatmarket.live/api/user/auth/resetPassword/${id}`,
          { password: resetData.password }
        );
        successmessage("Password updated")
        navigate("/")
      } catch (error:any) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.message === "User not found") {
                errormessage("User not found !");
            } else {
                errormessage(error.response.data.message);
            }
            errormessage(error.response.data.message);
          } else {
            errormessage("An unexpected error occurred. Please try again.");
          }
      }
    }
  };


  return (
    <>
      {contextHolder}
      <section className="login-bg">
        <Container>
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3">
                <div className="text-center">
                  <img
                    className="img-fluid text-center"
                    src="/assets/logo.png"
                    style={{ width: "150px" }}
                  />
                </div>
                <p
                  className="text-center log pt-3 mb-2"
                  style={{ color: "#fff", fontWeight: 600 }}
                >
                  Reset your password
                </p>
                <label
                  className="label py-2 sub-t"
                  style={{ color: "#fff", fontWeight: 600 }}
                >
                  New Password
                </label>
                <br />
                <Input.Password
                  placeholder="Enter your new password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setResetData({ ...resetData, password: e.target.value })
                  }
                />
                <br />
                <label
                  className="label py-2 sub-t"
                  style={{ color: "#fff", fontWeight: 600 }}
                >
                  Conform Password
                </label>
                <br />
                <Input.Password
                  placeholder="Enter your conform password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setResetData({ ...resetData, conformpass: e.target.value })
                  }
                />
                <br />
                <div className="text-center log-btn pt-3">
                  <Button
                    variant="contained"
                    className="but"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Resetpass;
