import React, { useEffect } from 'react'
import About from '../Home/About'
import Header from '../Home/Header'
import Footer from '../Footer/Footer'

const Aboutus = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <div>
            <Header/>
            <About/>
            <Footer/>
        </div>
    )
}

export default Aboutus
