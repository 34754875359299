import React, { useEffect } from "react";
import ProductView from "../Myorders/ProductView";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Header />
      <ProductView />
      <Footer />
    </>
  );
};

export default ProductDetails;
