import { Button } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";

const ReferalBanner = () => {
  return (
    <>
      <section className="py-4">
        <Container>
          <h2 className="title2 text-center pb-3">How Its Work</h2>
          <div className="row">
            <div className="col-lg-4 col-md-4  col-sm-6 col-6">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    src="/assets/nut1.png"
                    width={"50px"}
                  />
                </div>
                <div className="nut-con">
                  <span>Step 1</span>
                  <p>Select your Products</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    src="/assets/nut2.png"
                    width={"50px"}
                  />
                </div>
                <div className="nut-con">
                  <span>Step 2</span>
                  <p>Select your delivery date</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    src="/assets/nut3.png"
                    width={"50px"}
                  />
                </div>
                <div className="nut-con">
                  <span>Step 3</span>
                  <p>Delivered fresh straight to your door</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {/* <section className=" py-4">
          <div className="">
            <img className="img-fluid" src="/assets/refferalbanner.png" />
          </div>
      </section> */}
    </>
  );
};

export default ReferalBanner;
