import React, { useEffect } from 'react'
import Contact from '../Home/Contact';
import Header from '../Home/Header';
import Footer from '../Footer/Footer';

const Contactus = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <>
          <Header/>
          <Contact/>
          <Footer/>  
        </>
    )
}

export default Contactus
