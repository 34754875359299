import React, { useEffect } from "react";
import Banner from "../Home/Banner";
import Chicken from "../Home/Chicken";
import ReferalBanner from "../Home/ReferalBanner";
import Mutton from "../Home/Mutton";
import Nutritionalvalue from "../Home/Nutritionalvalue";
import Recipe from "../Home/Recipe";
import ReviewCarousel from "../Home/ReviewCarousel";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {" "}
      <Header />
      <Banner />
      <Chicken />
      <ReferalBanner />
      <Mutton />
      <Nutritionalvalue />
      <Recipe />
      <ReviewCarousel />
      <Footer />
    </>
  );
};

export default Home;
