import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { requestFranchise } from "../Providers/Api_Calls";
import { Snackbar } from "@mui/material";

const { Dragger } = Upload;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
  height: "90vh",
  overflowY: "scroll",
};

interface FranciesProps {
  open: boolean;
  closeModal: () => void;
}

const Francies: React.FC<FranciesProps> = ({ open, closeModal }) => {
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    license: "",
  });

  const [issnackbar, setissnackbar] = useState(false);
  const [snackbarCon, setsnackbarCon] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProgress, setisprogress] = useState(false);
  const [isError,setIserror]=useState("")

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content:any) => {
    messageApi.open({
      type: 'success',
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const errormessage = (content:any) => {
    messageApi.open({
      type: 'error',
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };


  // const convertToBase64 = (file: File): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  const handleRequestFranchise = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!file) {
      message.error("No file selected");
      return;
    }

    if (
      data.name === "" ||
      data.phone === "" ||
      data.email === "" ||
      data.address === "" ||
      data.city === "" ||
      data.state === "" ||
      data.pincode === ""
    ) {
      errormessage("Please fill all fields!")
    } else {
      // const base64File = await convertToBase64(file);
      setisprogress(true);
      try {
        // Create FormData and append fields
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("phone", data.phone);
        formData.append("email", data.email);
        formData.append(
          "address",
          `${data.address},${data.city},${data.state},${data.pincode}`
        );
        formData.append("image", file);
        const result = await requestFranchise(
          ApiEndPoints("requestFranchise"),
          formData
        );
        successmessage("Send Successfully...")
        setisprogress(false);
        setErrorMessage("");
        closeModal();
      } catch (error: any) {
        setisprogress(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errormessage(error.response.data.message);
        } else {
          console.error("An unexpected error occurred.");
          errormessage("An unexpected error occurred.");
        }
      }
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Store the selected file in the state
      setFile(file);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div>
      {contextHolder}
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="d-flex justify-content-between align-items-center box-title">
              <div>
                <h3>Become a Franchise</h3>
              </div>
              <div>
                <IoMdCloseCircleOutline
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={closeModal}
                />
              </div>
            </div>
            <div className="login">
              <label>
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <br />
              <label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="tel"
                onChange={(e) => setData({ ...data, phone: e.target.value })}
              />
              <br />
              <label>
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              <br />
              <label>
                Shop Address <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                onChange={(e) => setData({ ...data, address: e.target.value })}
              />
              <br />
              <div className="d-flex justify-content-between">
                <div>
                  <label>
                    City <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    onChange={(e) => setData({ ...data, city: e.target.value })}
                  />
                </div>
                <div className="mx-3">
                  <label>
                    State <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    onChange={(e) =>
                      setData({ ...data, state: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label>
                    Pin code <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    onChange={(e) =>
                      setData({ ...data, pincode: e.target.value })
                    }
                  />
                </div>
              </div>
              <br />
              <label>
                Shop License <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <div>
                <Dragger {...uploadProps} className="dragger-box">
                  <p className="ant-upload-drag-icon mb-0">
                    <InboxOutlined style={{ color: "#0D354F" }} />
                  </p>
                  <p className="ant-upload-text mb-0">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
              <br />
              <div className="text-center py-4">
                {!isProgress ? (
                  <Button variant="contained" onClick={handleRequestFranchise}>
                    Submit
                  </Button>
                ) : (
                  <h4>Sending...</h4>
                )}
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                <p className="py-2 text-center" style={{color:"red"}}>{isError}</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Francies;
