import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Francies from "../Home/Francies";
import BulkOrders from "../Home/BulkOrders";

const Footer = () => {
  const [isFranciesOpen, setIsFranciesOpen] = useState(false);
  const [isBulkOpen, setIsBulkOpen] = useState(false);
  const openModal = () => {
    setIsFranciesOpen(true);
  };

  const openulkModal = () => {
    setIsBulkOpen(true);
  };

  const closeModal = () => {
    setIsFranciesOpen(false);
  };
  const closeBulkModal = () => {
    setIsBulkOpen(false);
  };

  return (
    <>
      <Francies open={isFranciesOpen} closeModal={closeModal} />
      <BulkOrders open={isBulkOpen} closeModal={closeBulkModal} />
      <section className="footer-sec py-4">
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div>
                <Link to="/">
                  <img
                    className="img-fluid footer-logo"
                    alt="Footer-logo"
                    src="/assets/footer-logo.png"
                  />
                </Link>
                <p className="logo-para">
                  At <span>Meat Market</span> we are dedicated to provide the
                  finest quality chicken and mutton, ensuring every cut is
                  fresh, flavorful, and responsibly sourced to bring the best to
                  your table.
                </p>
                <div className="foot-links">
                <h2>Follow Us :</h2>
                <div className="social-media">
                  <a href="https://www.instagram.com/meatmarket_buyonline/" target="_blank">
                    <i className="fi fi-brands-instagram"></i>
                  </a>
                  <a className="mx-4" href="https://www.facebook.com/profile.php?id=61564134010130" target="_blank">
                    <i className="fi fi-brands-facebook"></i>
                  </a>
                  <a href="https://x.com/MarketMeat38607" target="_blank">
                    <i className="fi fi-brands-twitter-alt-circle"></i>
                  </a>
                </div>
              </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-lg-flex justify-content-center">
              <div className="foot-links">
                <h2>Quick Links</h2>
                <ul className="px-0">
                  <li>
                    <Link to="/chicken">Chicken</Link>
                  </li>
                  <li>
                    <Link to="/mutton">Mutton</Link>
                  </li>
                  <li>
                    <a onClick={openulkModal}>For Bulk Orders</a>
                  </li>
                  {/* <li>
                    <a onClick={openModal}>Become A Franchise</a>
                  </li> */}
                  <li>
                    <Link to="/my-order">My Orders</Link>
                  </li>
                </ul>
              </div>
            </div>
        
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="foot-links">
                <h2>Follow Us On Social Media</h2>
                <p>Social Media Always Update the latest News.</p>
                <div className="social-media">
                  <a>
                    <i className="fi fi-brands-instagram"></i>
                  </a>
                  <a className="mx-4">
                    <i className="fi fi-brands-facebook"></i>
                  </a>
                  <a>
                    <i className="fi fi-brands-twitter-alt-circle"></i>
                  </a>
                </div>
              </div>
            </div> */}
               <div className="col-lg-3 col-md-6 col-sm-12  d-lg-flex justify-content-center">
              <div className="foot-links">
                <h2>More Info</h2>
                <ul className="px-0">
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/termsandconditions">Terms and conditions</Link>
                  </li>
                  <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                  </li>
                  <li>
                  <Link to="/refund-policy">Refund policy</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="foot-links">
                <h2>Contact For Any Info</h2>
                <p>
                  Always at your Disposal For Any Questions. Fell Free To
                  Contact Us.
                </p>
                <div className="navCall">
                  <a href="tel:+91 9789983465">
                    <i className="fi fi-sr-circle-phone"></i> +91 9789983465
                  </a>
                </div>
                <div className="navCall py-2">
                  <a href="mailto:info@meatmarket.live">
                    <i className="fi fi-sr-envelope"></i> info@meatmarket.live
                  </a>
                </div>
              </div>
              <div>
                <img className="img-fluid" style={{width:"100px"}} src="/assets/fssai.png"/>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div className="text-center py-3">
        <p className="mb-0" style={{fontSize:"16px",fontWeight:600}}>@ Copyright 2024 Meat Market.All rights reserved</p>
      </div>
    </>
  );
};

export default Footer;
