import React from 'react'
import { Container } from 'react-bootstrap'

const Refund = () => {
    return (
        <>
          <section className='py-3'>
            <Container>
                <h2>Refund Policy:</h2>
                <p>At Meat Market, we are committed to providing you with the finest quality chicken and mutton. To ensure clarity and manage expectations, please review our refund policy below:</p>
                <h3>1. Order Cancellation</h3>
                <ul>
                    <li>
                        <p>Before Delivery: You may cancel your order at no charge before it is dispatched for delivery. If the cancellation is processed before the order is out for delivery, you will receive a full refund.</p>
                    </li>
                    <li><p>After Dispatch: Once your order is dispatched for delivery, cancellation is no longer possible. Due to the perishable nature of our products, we cannot accept cancellations or provide refunds after the order has left our facility.</p></li>
                </ul>
                <h3>2. Refund Eligibility</h3>
                <ul>
                    <li><p>Pre-Delivery Cancellations: If you cancel your order before it is dispatched, you will receive a full refund to your original payment method. Please allow [Number of Days] for the refund to be processed and reflected in your account.</p></li>
                    <li><p>Post-Delivery: Refunds are not available for orders once they have been dispatched. We are unable to accept returns or process refunds after delivery due to the nature of our products.</p></li>
                </ul>
            </Container>
            </section>  
        </>
    )
}

export default Refund
