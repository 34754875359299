import React, { useEffect } from "react";
import OrderDetails from "../Myorders/OrderDetails";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

const OrderInfoDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Header />
      <OrderDetails />
      <Footer />
    </>
  );
};

export default OrderInfoDetails;
