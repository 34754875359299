import React, { useEffect } from 'react'
import Header from '../Home/Header';
import Privacy from '../Home/privacy';
import Footer from '../Footer/Footer';

const PrivacyPloicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <>
          <Header/>
          <Privacy/>
          <Footer/>  
        </>
    )
}

export default PrivacyPloicy
