import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CardItem from "../CardItem";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { allProducts } from "../Providers/Api_Calls";

const Chicken = () => {
  const [products, setProducts] = useState<any[]>([]);

  // const getAllProduct = async () => {
  //   try {
  //     const response = await allProducts(ApiEndPoints("allProduct"),1);
  //     const chickenProducts = response.products.filter(
  //       (product: any) => product.category === "chicken"
  //     );
  //     setProducts(chickenProducts);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("allProduct"),1 );
      const totalPages = firstResponse.totalPages;
      let allfetchProducts: any[] = [...firstResponse.products];

      // Fetch the remaining pages
      for (let page = 2; page <= totalPages; page++) {
        const response = await allProducts(ApiEndPoints("allProduct"),page);
        allfetchProducts = [...allfetchProducts, ...response.products];
      }
      const chickenProducts = allfetchProducts.filter(
        (product: any) => product.category === "chicken"
      );

      const sortedChickenProducts = chickenProducts.sort(
        (a: any, b: any) => b.discountInPercent - a.discountInPercent
      );
      const limitedChickenProducts = sortedChickenProducts.slice(0, 6);
      setProducts(limitedChickenProducts);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const navigate = useNavigate();
  const handleNavChicken = () => {
    navigate("/chicken");
  };

  useEffect(() => {
    getAllProduct();
  }, []);
  return (
    <>
      <section className="py-4" style={{ borderBottom: "2px solid #0D354F" }}>
        <Container>
          <h2 className="title2 py-3 text-center">
            Buy <span>Chicken</span> Meat Online
          </h2>
          <div className="row">
            <div className="col-lg-3 sm-chickenbanner">
              <div>
                <img className="img-fluid" src="/assets/chickenposter.png" />
              </div>
            </div>
            <div className="col-lg-9">
              <div>
                <div className="row">
                  {products.length ? (
                    products.map((product) => (
                      <CardItem key={product._id} product={product} />
                    ))
                  ) : (
                    <h2 className="text-center">Loading ...!</h2>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pb-3 pt-4">
            <Button
              variant="contained"
              className="see-btn"
              onClick={handleNavChicken}
            >
              See More
            </Button>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Chicken;
